import { memo, useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn, Loader, Scroller, Cross } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import toKebabCase from '@Utils/toKebabCase';
import useMedias from '@Hooks/useMedias';
import ModalBase from '../ModalBase/ModalBase';
import styles from './ModalSidebar.module.css';

import type { ScrollState } from '@divlab/divanui';
import type {
  HTMLAttributes,
  FC,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  CSSProperties,
  Key,
} from 'react';
import type { Modal } from '@Stores/ModalWindows/typings';

export interface ModalSidebarProps<T = unknown> extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  modal: Modal<T>;
  loading?: boolean;
  title?: string;
  cnTitle?: string;
  cnTitleWrapper?: string;
  icons?: ReactElement;
  view?: 'default' | 'fullscreen';
  cnWrapperContent?: string;
  cnHead?: string;
  cnContent?: string;
  cnHeader?: string;
  cnFooter?: string;
  footer?: ReactElement;
  header?: ReactElement;
  scrollTop?: number;
  maxWidth?: number;
  refWrapperContent?: MutableRefObject<HTMLDivElement>;
  cnScroller?: string;
  onClose?: (e: MouseEvent | KeyboardEvent) => void;
  addingContent?: ReactElement;
  bordered?: boolean;
  preventScrollOnGrowing?: boolean;
  keyForChangeScrolledContent?: Key;
}

const ModalSidebar: FC<ModalSidebarProps> = (props) => {
  const {
    className,
    children,
    title,
    icons,
    modal,
    loading,
    view = 'default',
    cnWrapperContent,
    cnHead,
    cnTitle,
    cnTitleWrapper,
    cnContent,
    cnHeader,
    cnFooter,
    header,
    footer,
    scrollTop,
    maxWidth,
    refWrapperContent,
    cnScroller,
    onClose,
    addingContent,
    bordered,
    preventScrollOnGrowing,
    keyForChangeScrolledContent,
    ...restProps
  } = props;
  const { isMobile, isDesktop } = useMedias();
  const [visible, setVisible] = useState(false);
  const modals = ModalWindows.useModals();
  const refBackdrop = useRef<HTMLDivElement>();
  const [scrollPosition, setScrollPosition] = useState<ScrollState>('none');
  const id = toKebabCase(modal.id);

  const styleWrapperContent = useMemo<CSSProperties>(() => {
    if (!maxWidth || isDesktop) return null;

    return { maxWidth: `${maxWidth}px` };
  }, [isDesktop, maxWidth]);

  const handleClickBackdrop = useCallback(
    (e: MouseEvent) => {
      if (e.currentTarget !== refBackdrop.current) return;
      if (onClose) onClose(e);
    },
    [onClose],
  );

  useEffect(() => {
    // Обновляем состояние в следующем тике event loop, иначе анимация открытия модального окна
    // не запустится после hot-reload в режиме разработки
    setTimeout(() => setVisible(modal.visible));
  }, [modal.visible]);

  const handleChangeState = useCallback((e: MouseEvent, state: ScrollState) => {
    setScrollPosition(state);
  }, []);

  return (
    <ModalBase id={id} view='success'>
      <div
        {...restProps}
        className={cn(
          styles.modal,
          {
            [styles.viewDefault]: view === 'default',
            [styles.viewFullscreen]: view === 'fullscreen',
            [styles.hidden]: modal.hidden,
            [styles.needBorder]: bordered,
          },
          className,
        )}
        data-testid={`${id}-modal`}
      >
        <CSSTransition
          classNames={{ enterActive: styles.enterActive, enterDone: styles.enterDone }}
          in={visible}
          unmountOnExit={!modal.hidden}
          timeout={modals.timeout}
        >
          <div className={styles.wrapper}>
            <div className={styles.backdrop} ref={refBackdrop} onClick={handleClickBackdrop} />
            <div
              className={cn(styles.wrapperContent, cnWrapperContent)}
              style={styleWrapperContent}
              ref={refWrapperContent}
            >
              {loading ? (
                <div className={styles.wrapperPreloader}>
                  <Loader theme='dark' size='huge' />
                </div>
              ) : (
                <>
                  <div
                    className={cn(
                      styles.head,
                      {
                        [styles.bordered]:
                          bordered && scrollPosition !== 'top' && scrollPosition !== 'none',
                      },
                      cnHead,
                    )}
                  >
                    <div className={cn(styles.titleWrapper, cnTitleWrapper)}>
                      <div className={cn(styles.title, cnTitle)}>{title}</div>
                      {icons}
                    </div>
                    <Cross
                      className={styles.iconClose}
                      view={isMobile ? 'default' : 'circle'}
                      onClick={onClose}
                      data-testid={`${id}-modal-close`}
                    />
                  </div>
                  {header && <div className={cn(styles.header, cnHeader)}>{header}</div>}
                  <Scroller
                    key={keyForChangeScrolledContent}
                    scrollTop={scrollTop}
                    className={cn(styles.scroller, cnScroller)}
                    onChangeState={bordered ? handleChangeState : null}
                    preventScrollOnGrowing={preventScrollOnGrowing}
                  >
                    <div className={cn(styles.containerContent, cnContent)}>{children}</div>
                  </Scroller>

                  {footer && (
                    <div
                      className={cn(styles.footer, cnFooter, {
                        [styles.bordered]:
                          bordered && scrollPosition !== 'bottom' && scrollPosition !== 'none',
                      })}
                    >
                      {footer}
                    </div>
                  )}
                  {addingContent}
                </>
              )}
            </div>
          </div>
        </CSSTransition>
      </div>
    </ModalBase>
  );
};

export default memo(ModalSidebar);
